import { useState, useCallback, useMemo } from 'react';
import { isEmpty } from 'common/utils/utils';
import { useJiraFields } from 'features/IntegrationSection/components/jira/jira.consts';

export const useIntegrationDropdown = (options, field, onChange) => {
  const { jiraIssueField, jiraParentIssueField } = useJiraFields();
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [internalFilter, setInternalFilter] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  const isEnabled = useMemo(() => !isEmpty(options) || internalFilter, [options, internalFilter]);

  const isIssueField = useMemo(() => [jiraIssueField.name, jiraParentIssueField.name].includes(field?.name), [field.name, jiraIssueField, jiraParentIssueField]);

  const filterOptions = useCallback((options, searchTerm) => {
    if (!searchTerm) return options;
  
    return options?.filter(option => 
      option.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
      isIssueField && option.key.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [isIssueField]);

  const handleOpenMenu = useCallback(() => {
    setDropdownMenuOpen(isEnabled);
  }, [isEnabled]);

  const handleCloseMenu = useCallback((e) => {
    e.stopPropagation();
    setDropdownMenuOpen(false);
  }, []);

  const handleSelect = useCallback((option) => {
    setValue(option);
    setDropdownMenuOpen(false);
    onChange(option);
  }, [onChange]);

  const onSelectClear = useCallback(() => {
    handleSelect(null);
    setInternalFilter('');
  }, [handleSelect]);

  const actionsProps = useMemo(() => ({
    isMultiSelect: false,
    isDirty: false,
    isClearHidden: field.required,
    isClearDisabled: isEmpty(value),
    isSelectAllHidden: true,
    clearValueHandler: onSelectClear,
  }), [field.required, value, onSelectClear]);

  return {
    dropdownMenuOpen,
    value,
    internalFilter,
    filteredOptions,
    isEnabled,
    setInternalFilter,
    setFilteredOptions,
    filterOptions,
    handleOpenMenu,
    handleCloseMenu,
    handleSelect,
    onSelectClear,
    actionsProps
  };
}; 