import { useMemo } from 'react';
import useTexts from '../../useTexts';

export const JIRA_FIELD_COUNT = 2;
export const TIMEOUT_30_SECONDS = 30000;
export const SEARCH_DEBOUNCE_TIME = 300;
export const JIRA_API_PAGE_SIZE = 20;
export const JIRA_ACTION_FIELD_NAME = 'sr_cust_addon_jira_action';
export const JIRA_ISSUEKEY_FIELD_NAME = 'sr_cust_addon_jira_issuekey';
export const JIRA_ADDON_FIELD_PREFIX = 'sr_cust_addon_jira_';

export const JIRA_ERROR_TYPES = {
  FETCH_JIRA_DATA: 'errorFetchingJiraData',
  FIELDS_INVALID: 'fieldsInvalidError',
  CREATE_JIRA_ISSUE: 'createJiraIssueFailure',
  UNLINK_ISSUE: 'errorUnlinkIssue',
};

export const REQUIRED_JIRA_FIELDS = [
  JIRA_ACTION_FIELD_NAME,
  JIRA_ISSUEKEY_FIELD_NAME
];
export const SINGLE_ROW_COUNT = 1;

export const useJiraFields = () => {
  const { FIELDS } = useTexts();
  
  return useMemo(() => ({
    jiraProjectField: {
      name: 'jiraProject',
      displayName: FIELDS.jiraProjectField,
      type: 'select',
      required: true,
    },
    jiraTypeField: {
      name: 'jiraType',
      displayName: FIELDS.jiraTypeField,
      type: 'select',
      required: true,
    },
    jiraParentIssueField: {
      name: 'jiraParentIssue',
      displayName: FIELDS.jiraParentIssueField,
      type: 'select',
      required: false,
    },
    jiraAssigneeField: {
      name: 'jiraAssignee',
      displayName: FIELDS.jiraAssigneeField,
      type: 'select',
      required: false,
    },
    jiraIssueField: {
      name: 'jiraIssue',
      displayName: FIELDS.jiraIssueField,
      type: 'select',
      required: true,
    },
  }), [FIELDS]);
};

export const jiraActionFieldValues = {
  CREATE: {
    value: "Create",
    valueToDisplay: null,
    id: 1,
    valueClass: 0,
  },
  LINK: {
    value: "Link",
    valueToDisplay: null,
    id: 2,
    valueClass: 0,
  },
};

export const UNLINK_JIRA_ISSUE = 'unlinkJiraIssue';

export const initialFieldValues = {
  jiraProject: null,
  jiraType: null,
  jiraParentIssue: null,
  jiraAssignee: null,
  jiraIssue: null,
};

export const initialFieldOptions = {
  jiraProject: [],
  jiraType: [],
  jiraParentIssue: [],
  jiraAssignee: [],
  jiraIssue: [],
};

export const initialFieldSearchFilters = {
  jiraProject: '',
  jiraAssignee: '',
  jiraIssue: '',
  jiraParentIssue: '',
};

export const MIN_CHARS_FOR_API_SEARCH = {
  jiraProject: 1,
  jiraIssue: 3,
  jiraParentIssue: 3,
  jiraAssignee: 2,
};

export const JIRA_CONNECTION_STATUS = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected'
};
